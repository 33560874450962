<template>
    <!--begin::Container-->
        <div class="container">
        <!--begin::Dashboard-->
        

        <div class="row">

            <div class="col-xl-2">
                <div class="card card-custom card-stretch gutter-b ">
                    <div class="card-header border-0 py-5">
                        <div class="symbol symbol-40 symbol-light mr-5">
                                            <span class="symbol-label">
                                                <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-04-09-093151/theme/html/demo12/dist/../src/media/svg/icons/General/Star.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                   <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24"/>
                                                        <circle fill="#000000" opacity="0.3" cx="20.5" cy="12.5" r="1.5"/>
                                                        <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 6.500000) rotate(-15.000000) translate(-12.000000, -6.500000) " x="3" y="3" width="18" height="7" rx="1"/>
                                                        <path d="M22,9.33681558 C21.5453723,9.12084552 21.0367986,9 20.5,9 C18.5670034,9 17,10.5670034 17,12.5 C17,14.4329966 18.5670034,16 20.5,16 C21.0367986,16 21.5453723,15.8791545 22,15.6631844 L22,18 C22,19.1045695 21.1045695,20 20,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,6 C2,4.8954305 2.8954305,4 4,4 L20,4 C21.1045695,4 22,4.8954305 22,6 L22,9.33681558 Z" fill="#000000"/>
                                                    </g>
                                                </svg><!--end::Svg Icon--></span>
                                            </span>
                                        </div>
                    </div>
                    <div class="card-body py-0">
                    
                        <b style="font-size: 40px;">{{$parent.Payload.User.Balance != undefined ? $parent.Payload.User.Balance.toFixed(2) : '0.00'}}</b> <span class="text-muted mt-3 font-weight-bold font-size-sm">USDT</span>
                         <br/> 
                         
                    </div>
                </div>
            </div>

            <div class="col-xl-2">
                <div class="card card-custom card-stretch gutter-b ">
                    <div class="card-header border-0 py-5">
                        <div class="symbol symbol-40 symbol-light mr-5">
                                            <span class="symbol-label">
                                                <span class="svg-icon svg-icon-primary svg-icon-2x"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-04-19-122603/theme/html/demo1/dist/../src/media/svg/icons/General/User.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <polygon points="0 0 24 0 24 24 0 24"/>
                                                    <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                                    <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                                                </g>
                                            </svg><!--end::Svg Icon--></span>
                                            </span>
                                        </div>
                    </div>
                    <div class="card-body py-0">
                    
                        <b style="font-size: 40px;">{{transactions.length}}</b> <span class="text-muted mt-3 font-weight-bold font-size-sm">Transactions</span>
                         <br/> 
                         
                    </div>
                </div>
            </div>

            <div class="col-xl-8">
                <!--begin::Advance Table Widget 1-->
                <div class="card card-custom card-stretch gutter-b ">
                    <!--begin::Header-->
                    <div class="card-header border-0 py-5">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label font-weight-bolder text-dark-75">Invite Link</span>
                            <span class="text-muted mt-3 font-weight-bold font-size-sm">Use your referral link to invite your friends and get the 25% of every subscription in USDT!</span>
                        </h3>

                         
                        
                    </div>
                    <!--end::Header-->
                    <!--begin::Body-->
                    <div class="card-body py-0">
                        <div class="pb-5">

                            <div class="row">
                                <div class="form-group col-md-8 col-lg-8 col-sm-12">
                                    <div class="input-group">
                                        <input type="text" id="ref-link" class="form-control" :value="'https://panel.trustsignals.ai/#/login/' + $parent.UserID">
                                        <div class="input-group-append">
                                            <button @click="copyRef" class="btn btn-primary" type="button">Copy invite link!</button>
                                        </div>
                                    </div>
                            
                                </div>
                                <div class="form-group col-md-4 col-lg-4 col-sm-12">
                                 <a @click="withdraw" class="btn btn-primary font-weight-bolder font-size-sm ml-3">
                                <span class="svg-icon svg-icon-md svg-icon-white">
                                    <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Add-user.svg-->
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                         <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24"/>
                                            <path d="M22,17 L22,21 C22,22.1045695 21.1045695,23 20,23 L4,23 C2.8954305,23 2,22.1045695 2,21 L2,17 L6.27924078,17 L6.82339262,18.6324555 C7.09562072,19.4491398 7.8598984,20 8.72075922,20 L15.381966,20 C16.1395101,20 16.8320364,19.5719952 17.1708204,18.8944272 L18.118034,17 L22,17 Z" fill="#000000"/>
                                            <path d="M2.5625,15 L5.92654389,9.01947752 C6.2807805,8.38972356 6.94714834,8 7.66969497,8 L16.330305,8 C17.0528517,8 17.7192195,8.38972356 18.0734561,9.01947752 L21.4375,15 L18.118034,15 C17.3604899,15 16.6679636,15.4280048 16.3291796,16.1055728 L15.381966,18 L8.72075922,18 L8.17660738,16.3675445 C7.90437928,15.5508602 7.1401016,15 6.27924078,15 L2.5625,15 Z" fill="#000000" opacity="0.3"/>
                                            <path d="M11.1288761,0.733697713 L11.1288761,2.69017121 L9.12120481,2.69017121 C8.84506244,2.69017121 8.62120481,2.91402884 8.62120481,3.19017121 L8.62120481,4.21346991 C8.62120481,4.48961229 8.84506244,4.71346991 9.12120481,4.71346991 L11.1288761,4.71346991 L11.1288761,6.66994341 C11.1288761,6.94608579 11.3527337,7.16994341 11.6288761,7.16994341 C11.7471877,7.16994341 11.8616664,7.12798964 11.951961,7.05154023 L15.4576222,4.08341738 C15.6683723,3.90498251 15.6945689,3.58948575 15.5161341,3.37873564 C15.4982803,3.35764848 15.4787093,3.33807751 15.4576222,3.32022374 L11.951961,0.352100892 C11.7412109,0.173666017 11.4257142,0.199862688 11.2472793,0.410612793 C11.1708299,0.500907473 11.1288761,0.615386087 11.1288761,0.733697713 Z" fill="#000000" fill-rule="nonzero" transform="translate(11.959697, 3.661508) rotate(-90.000000) translate(-11.959697, -3.661508) "/>
                                        </g>
                                    </svg>
                                    <!--end::Svg Icon-->
                                </span>Request withdraw</a>
                                </div>

                            
                                
                            </div>

                        </div>

                        

                       
                        
                    </div>
                    <!--end::Body-->
                </div>
                <!--end::Advance Table Widget 1-->
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12">
                <div class="card card-custom gutter-b" style="height:650px; max-height:650px;">
                    <!--begin::Header-->
                    <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark">Latests transactions</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-sm">Your refered users transactions' status.</span>
                    </h3>
                    
                    </div>
                    <!--end::Header-->
                    <!--begin::Body-->
                    <div class="card-body pt-2 pb-0 mt-n3">
                    <div class="tab-content mt-5" id="myTabTables6">
                        
                        
                        <!--begin::Tap pane-->
                        <div class="tab-pane fade active show" id="kt_tab_pane_6_3" role="tabpanel" aria-labelledby="kt_tab_pane_6_3">
                            <div class="table-responsive" style="min-height: 300px;">
                                <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                                <thead>
                                    <tr class="text-left">
                                        <th style="min-width: 80px" class="pl-7">
                                            
                                        </th>
                                        <th style="min-width: 100px">DATE</th>
                                        <th style="min-width: 100px" class="text-right">STATUS</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="transactions.length == 0">
                                        <td colspan="3">
                                            <center class="mt-10">
                                                <img src="assets/media/svg/illustrations/nodata.svg" style="height: 50px;" />
                                                <h3 class="mt-5"> There aren't transactions.</h3>

                                            </center>
                                        </td>
                                    </tr>
                                    <tr v-for="(transaction, k) in transactions" :key="'transaction-' + k">
                                        
                                        <td>
                                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{transaction.Email}}</span>
                                            <span class="text-muted font-weight-bold">{{transaction.Amount.toFixed(2)}}</span>
                                        </td>
                                        
                                        <td>
                                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{transaction.Email}}</span>
                                            <span class="text-muted font-weight-bold d-block">{{(new Date(transaction.CreatedAt)).toLocaleDateString()}} {{(new Date(transaction.CreatedAt)).toLocaleTimeString()}}</span>
                                        </td>

                                        <td class="pr-0 text-right">
                                            <a class="btn btn-light-primary font-weight-bolder font-size-sm" :style="transaction.Status == 'COMPLETED' ? 'color: #22af91; background-color: #4dd0b357;' : 'color: #ff7aa1; background-color: #a83254;'"> {{transaction.Status}}</a>
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            <!--begin::Table-->
                            <!--end::Table-->
                        </div>
                        <!--end::Tap pane-->
                    </div>
                    </div>
                    <!--end::Body-->
                    
                </div>
            </div>
        </div>

        
        
        <!--end::Dashboard-->
        </div>
        <!--end::Container-->
</template>

<script>
import Maurer from '../../components/Maurer.vue';
import hash from 'js-sha512';
const qrcode = require('yaqrcode');

export default {
    methods: {

        withdraw(){
            var vm = this;
            vm.$swal({
            title: 'Payout request',
            html:
                '<input id="swal-input1" placeholder="Amount in USDT" class="swal2-input">' + 
                '<input id="swal-input2" placeholder="USDT Address" class="swal2-input">',
            confirmButtonText: 'Send request',
            showCancelButton: true,
            cancelButtonText: 'Cancel',

            preConfirm: function () {
                return new Promise(function (resolve) {
                resolve([
                    window.$('#swal-input1').val(),
                    window.$('#swal-input2').val()
                ])
                })
            },
            onOpen: function () {
                window.$('#swal-input1').focus()
            }
            }).then(function (result) {
                if(result.isConfirmed){
                    var amount = parseFloat(result.value[0]), address = result.value[1];
                    Maurer.api.user.redeem({
                        Amount: amount,
                        Address: address,
                        Type: 'Payout',
                        Coin: 'USDT'
                    }, function (d) {
                        Maurer.api.success(d);
                    }, function (d) {
                        Maurer.api.error(d);
                    });
                }
            }).catch(vm.$swal.noop)
        },

        copyRef(){
            var copyText = document.getElementById("ref-link");

            copyText.select();
            copyText.setSelectionRange(0, 99999);

            document.execCommand("copy");
        },

       

        init(){
            var vm = this;
            Maurer.api.user.referred_transactions(function (d) {
                if(d.transactions != null){
                    vm.transactions = d.transactions;
                }
            }, function (d) {
                Maurer.api.error(d);
            });

        },

    },

    mounted(){
        var vm = this;
        vm.$parent.init();
        vm.init();
    },

    data(){
        return {
            transactions: []
            
        }
    }
}
</script>

<style scoped>
.disabled-div
{
  pointer-events: none;
  opacity: 0.5;
}
</style>